import { store } from '~/plugins/vuex';

export default defineNuxtRouteMiddleware(async (_, from) => {
  const { user } = store.state.auth;
  const isLoggedIn = !!user;

  if (!isLoggedIn) {
    return await navigateTo({ path: '/login', query: { redirect: from.path } });
  }
});
